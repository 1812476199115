import React from "react";
import "./assets/css/main.css";

import { Route } from "react-router-dom";
// http://127.0.0.1:8000/
import { Home } from "./screens/Home";
import { SingleMachineScreen } from "./screens/SingleMachineScreen";

import { AnimatedSwitch } from "react-router-transition";

function App() {
  return (
    <AnimatedSwitch
      atEnter={{ opacity: 0 }}
      atLeave={{ opacity: 0 }}
      atActive={{ opacity: 1 }}
      className="switch-wrapper"
    >
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/machines" exact>
        <SingleMachineScreen />
      </Route>
    </AnimatedSwitch>
  );
}

export default App;
