import React, { useState, useEffect, useContext } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  Legend,
} from "chart.js";
import { PageLayout } from "../components/PageLayout";
import { MainContext } from "../store/mainContext";
import { StatusBox } from "../components/StatusBox";
import { MachinesHealthChart } from "../components/charts/MachinesHealthChart";
import { GraphChart } from "../components/charts/GraphChart";
import { BarChart } from "../components/charts/BarChart";
import { SingleMachine } from "../components/charts/SingleMachine";
import { Loader } from "../components/Loader";
import constants from "../store/constants";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
  Title
);

export const Home = () => {
  const ctx = useContext(MainContext);
  const [data, setData] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (ctx.currentCustomer?.currentCustomer) {
      setIsLoading(true);
      fetch(
        constants.apiUrl+"Home/" +
          ctx.currentCustomer?.currentCustomer?.CustomerID,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            console.log(result);
            if (result) {
              setData(result);
            }
            setIsLoading(false);
          },
          (error) => {
            console.log(JSON.stringify(error));
            setIsLoading(false);
          }
        );
    }
  }, [ctx.currentCustomer, ctx.currentCustomer.currentCustomer]);
  if (!data || data.length === 0) return <></>;

  return (
    <PageLayout>
      <Loader loading={loading} />
      <div className="container-fluid py-3">
        <div className="row">
          <StatusBox
            count={data.machines_monitored}
            title="Machines Monitored"
          />
          <StatusBox title="Active Machines" count={data.active_machines} />
          <StatusBox title="Vibration Sensors" count={data.vibration_sensors} />
          <StatusBox title="Problems Detected" count={data.problems_detected} />
        </div>
      </div>
      {(data?.faults ||
        data?.overall_health ||
        data?.customer_health_scores) && (
        <div className="container-fluid py-3">
          <div className="row">
            <div className="col-lg-7">
              <h3 className="text-center mb-4">Overall Health</h3>
              <div className="row justify-content-center ">
                {data?.overall_health && (
                  <div className="col-lg-4 py-2">
                    <MachinesHealthChart
                      values={data.overall_health}
                      settings={ctx.settings.settings}
                    />
                  </div>
                )}
                {data?.customer_health_scores[0].length >= 1 && (
                  <div className="col-lg-8 py-2">
                    <GraphChart
                      labels={data.customer_health_scores[0].slice(
                        -ctx.settings.settings.home_health_chart_days_count
                      )}
                      values={data.customer_health_scores[1].slice(
                        -ctx.settings.settings.home_health_chart_days_count
                      )}
                      settings={ctx.settings.settings}
                    />
                  </div>
                )}
              </div>
            </div>
            {data.faults[0].length >= 1 && (
              <div className="col-lg-5">
                <h3 className="text-center mb-4">Faults</h3>
                <BarChart labels={data.faults[0]} values={data.faults[1]} />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="container-fluid py-3">
        <div className="d-inline-block  text-center ">
          {data?.machine_type_results?.map((item, index) => {
            return (
              <SingleMachine
                key={index.toString()}
                label={data.machine_type_results[0][index]}
                values={data.machine_type_results[1][index]}
                settings={ctx.settings.settings}
              />
            );
          })}
        </div>
      </div>
    </PageLayout>
  );
};
