import React from "react";
import { Line } from "react-chartjs-2";
import constants from "../../store/constants";
export const GraphChart = (props) => {
  const labels = props.labels;
  const values = props.values;
  const settigns = props.settings;

  const options = {
    responsive: true,
    scales: {
      y: {
        suggestedMin: 0,
        step: 1,
        suggestedMax: 10,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          borderColor: "rgba(0,0,0,0)",
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
          },
        },
        gridLines: {
          lineWidth: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const dataLine = {
    labels,
    datasets: [
      {
        data: values,
        borderColor: "rgba(0, 0, 0,1)",
        backgroundColor: "rgba(0, 0, 0,1)",
        yAxisID: "y",
      },
    ],
  };

  return (
    <div className="line-chart">
      <div className="pos-z">
        <Line options={options} data={dataLine} />
      </div>
      <div
        className="bg"
        style={{
          background: settigns.green,
          background:
            "linear-gradient( 0deg, " +
            settigns.red +
            " 0%, " +
            settigns.yellow +
            " 25%, " +
            settigns.orange +
            " 50%, " +
            settigns.green +
            " 75% )",
        }}
      ></div>
    </div>
  );
};
