import { Doughnut } from "react-chartjs-2";
import { colorMaps } from "../../services/helpers";

export const SingleMachine = (props) => {
  const settings = props.settings;
  const options = {
    responsive: true,
    cutout: 75,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    elements: {
      center: {
        text: "Red is 2/3 the total numbers",
        color: "#FF6384", // Default is #000000
        fontStyle: "Arial", // Default is Arial
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25, // Default is 25 (in px), used for when text wraps
      },
    },
  };

  const data = {
    // labels: ["Red", "Blue", "Yellow", "Green"],
    datasets: [
      {
        label: "# of Votes",
        data: props.values,
        backgroundColor: [
          settings.red,
          settings.orange,
          settings.yellow,
          settings.green,
        ],
        borderColor: [
          settings.red,
          settings.orange,
          settings.yellow,
          settings.green,
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="single-machine-dougnut m-3">
      <p>{props.label}</p>
      <Doughnut data={data} options={options} />
    </div>
  );
};
