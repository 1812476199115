import React from "react";
import { Pie } from "react-chartjs-2";
import { colorMaps } from "../../services/helpers";

export const MachinesHealthChart = (props) => {
  const settings = props.settings;
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    datasets: [
      {
        label: "# of Votes",
        data: props.values,
        backgroundColor: [
          settings.red,
          settings.orange,
          settings.yellow,
          settings.green,
        ],
        borderColor: [
          settings.red,
          settings.orange,
          settings.yellow,
          settings.green,
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={data} options={options} />;
};
