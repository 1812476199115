import react from "react";

export const Loader = (props) => {
  return (
    <div
      className={
        "loader-wrapper d-flex align-items-center justify-content-center" +
        (props.loading ? " show" : "")
      }
    >
      <div className={"lds-dual-ring "}></div>
    </div>
  );
};
