import React, { useState, useContext } from "react";
import icon from "../assets/icons/arrowDown.png";
import { MainContext } from "../store/mainContext";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import constants from "../store/constants";

export const Header = () => {
  const ctx = useContext(MainContext);
  const [dropdownActive, setDropdownActive] = useState(false);
  const [dropdownActive1, setDropdownActive1] = useState(false);
  const currentCustomer = ctx.currentCustomer.currentCustomer;
  const currentMachine = ctx.currentMachine.currentMachine;
  const customers = ctx.customers.customers;
  const machines = ctx.machines.machines;

  const location = useLocation();

  const fetchData = (cid) => {
    fetch(constants.apiUrl + cid, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result) {
            ctx.customer?.setCurrentCustomer(result.currentCustomer);
            ctx.machines?.setMachines(result.machines);
            ctx.currentMachine?.setCurrentMachine(result.machines[0]);
          }
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
  };

  if (!currentCustomer || !customers) return <></>;

  return (
    <section className="header d-flex align-items-center">
      <div className="container-fluid ">
        <div className="row d-flex justify-content-end">
          <div className="col-lg-8 header-links align-items-center d-flex py-2">
            <Link to="/">Home</Link>
            <Link to="/machines">Machines</Link>
          </div>
          {location.pathname === "/machines" ? (
            <div className="col-lg-2 ">
              <div
                className={
                  "custom-dropdown-wrapper my-2 " +
                  (dropdownActive ? "open" : "")
                }
                onClick={() => {
                  setDropdownActive1(false);
                  setDropdownActive((prev) => !prev);
                }}
              >
                {currentMachine?.MachineName && (
                  <div className="title">{currentMachine.MachineName}</div>
                )}
                {machines.length > 1 && (
                  <>
                    <img src={icon} alt="" />
                    <div className="items">
                      {machines.map((machine, index) => {
                        if (machine.MachineID !== currentMachine.MachineID)
                          return (
                            <div
                              key={index.toString()}
                              className="item"
                              onClick={() => {
                                ctx.currentMachine.setCurrentMachine(machine);
                              }}
                            >
                              {machine.MachineName}
                            </div>
                          );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : (
            <div className="col-lg-2"></div>
          )}
          <div className="col-lg-2 ">
            <div
              className={
                "custom-dropdown-wrapper my-2 " +
                (dropdownActive1 ? "open" : "")
              }
              onClick={() => {
                setDropdownActive(false);
                setDropdownActive1((prev) => !prev);
              }}
            >
              {currentCustomer?.CustomerName && (
                <div className="title">{currentCustomer.CustomerName}</div>
              )}
              <img src={icon} alt="" />
              <div className="items">
                {customers.map((customer, index) => {
                  if (customer.CustomerID !== currentCustomer.CustomerID)
                    return (
                      <div
                        key={index.toString()}
                        className="item"
                        onClick={() => {
                          ctx.currentCustomer.setCurrentCustomer(customer);
                          fetchData(customer.CustomerID);
                        }}
                      >
                        {customer.CustomerName}
                      </div>
                    );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
