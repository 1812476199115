import React, { useState, useEffect, useContext } from "react";
import { GraphChart } from "../components/charts/GraphChart";
import { Loader } from "../components/Loader";

import { PageLayout } from "../components/PageLayout";
import { colorMaps } from "../services/helpers";
import constants from "../store/constants";
import { MainContext } from "../store/mainContext";

export const SingleMachineScreen = () => {
  const ctx = useContext(MainContext);
  const [loading, setIsLoading] = useState(true);
  const settings = ctx.settings.settings;
  const [data, setData] = useState(undefined);
  useEffect(() => {
    if (
      ctx.currentCustomer?.currentCustomer &&
      ctx.currentMachine?.currentMachine
    ) {
      setIsLoading(true);

      fetch(
        constants.apiUrl+ "machines/" +
          ctx.currentCustomer?.currentCustomer?.CustomerID +
          "/" +
          ctx.currentMachine?.currentMachine?.MachineID,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result) {
              console.log('machine');
              console.log('result')
              setData(result);
              setIsLoading(false);
            } else {
              // setData(undefined);
              // setIsLoading(false);
            }
          },
          (error) => {
            console.log(JSON.stringify(error));
            // setData(undefined);
            // setIsLoading(false);
          }
        );
    }
  }, [ctx]);

  const TableItem = (props) => {
    let bg = colorMaps(props.score, settings);
    let textColor = "#ffff";
    if (bg === settings.yellow) {
      textColor = "#000";
    }
    return (
      <td
        style={{
          backgroundColor: bg,
          color: textColor,
        }}
      >
        {props.score}
      </td>
    );
  };

  return (
    <PageLayout>
      <Loader loading={loading} />
      <div className="container-fluid py-5">
        <div className="row justify-content-between">
          <div className="col-lg-6">
            <div className="row ">
              <div className="col-lg-5 offset-lg-2">
                <div className="image-wrapper">
                  <img
                    src={ctx.currentMachine.currentMachine.MachineImageURL}
                    alt=""
                  />
                </div>
              </div>
            </div>
            {data?.machine_sensors_health_scores ? (
              <div className="row py-4">
                <div className="col-lg-9">
                  <h5 className="text-gray text-center pb-3">
                    Overall Vibration & Temp.
                  </h5>
                  <div class="table-responsive">
                    <table class="table mb-0">
                      <tr>
                        <th className="table_title border-top-0">H</th>
                        {data?.machine_sensors_health_scores.map(
                          (item, index) => (
                            <TableItem score={item.Xvibration} />
                          )
                        )}
                      </tr>
                      <tr>
                        <th className="table_title border-top-0">V</th>
                        {data?.machine_sensors_health_scores.map(
                          (item, index) => (
                            <TableItem score={item.Yvibration} />
                          )
                        )}
                      </tr>
                      <tr>
                        <th className="table_title border-top-0">A</th>
                        {data?.machine_sensors_health_scores.map(
                          (item, index) => (
                            <TableItem score={item.Zvibration} />
                          )
                        )}
                      </tr>
                      <tr>
                        <th className="table_title border-top-0">T</th>
                        {data?.machine_sensors_health_scores.map(
                          (item, index) => (
                            <TableItem score={item.Temperature / 10} />
                          )
                        )}
                      </tr>
                    </table>
                  </div>
                </div>
                {data?.chart[0] && (
                  <div className="col-lg-9 py-4">
                    <h5 className="text-gray text-center mt-3 pb-3">Health Score</h5>
                    <GraphChart
                      labels={data.chart[0].slice(
                        -settings.single_machine_chart_days_count
                      )}
                      values={data.chart[1].slice(
                        -settings.single_machine_chart_days_count
                      )}
                      settings={settings}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
          <div className="col-lg-6">
            {data && (
              <>
                <div className="pb-4">
                  <h1 className="pb-2 ">
                    Diagnosis Results & Actions
                  </h1>
                  <div className="line green" />
                  <ol className="mt-4">
                    {data?.notes.map((note, index) => {
                      return (
                        <li className="single-dianostics-item">
                          {note.NoteImageURL && (
                            <img src={note.NoteImageURL} alt="" />
                          )}
                          {note.NoteText && <p>{note.NoteText}</p>}
                        </li>
                      );
                    })}
                  </ol>
                </div>
                <div className="pb-4">
                  <h1 className="pb-2 ">
                  Corrective Actions
                  </h1>
                  <div className="line green" />
                  <ol className="mt-4">
                    {data?.reccomendations.map((reccomendation, index) => {
                      return (
                        <li className="single-dianostics-item">
                          {reccomendation.RecommendationText && (
                            <p>{reccomendation.RecommendationText}</p>
                          )}
                        </li>
                      );
                    })}
                  </ol>
                  {/* <ol>
                    <li>
                      <b>test</b>
                      <ul>
                        <li>text</li>
                        <li>text</li>
                        <li>text</li>
                      </ul>
                    </li>
                    <li>1</li>
                    <li>1</li>
                  </ol> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
