import React from "react";
export const StatusBox = (props) => {
  return (
    <div className="col-xl-2 col-lg-3 col-md-6 py-2 py-lg-0">
      <div className="boxes text-center py-2">
        <span className="type text_white d-block">{props.title}</span>
        <span className="number text_white d-block">{props.count}</span>
      </div>
    </div>
  );
};
