import React, { useContext, useEffect,useState } from "react";
import { Header } from "./Header";
import { MainContext } from "../store/mainContext";
import { Loader } from "./Loader";
export const PageLayout = (props) => {
  const ctx = useContext(MainContext);
  const [loading, setIsLoading] = useState(ctx.loading.isLoading);
  useEffect(() => {
    setIsLoading(ctx.loading.isLoading);
  }, [ctx.loading]);

  return (
    <>
      <Loader loading={loading} />
      <Header />
      {props.children}
    </>
  );
};
