import React from "react";
import { Bar } from "react-chartjs-2";

export const BarChart = (props) => {
  const labels = props.labels;
  const values = props.values;

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        suggestedMin: 0,
        suggestedMax: 10,
        ticks: {
          stepSize: 1,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: values,
        backgroundColor: "rgba(0,0,0,0.05)",
        borderColor: "#000",
        borderWidth: 1,
      },
    ],
  };
  return <Bar options={options} data={data} />;
};
